<template>
  <div
    v-show="
      userStore.user &&
      userStore.user.email &&
      (userStore.user.email.includes('@altinget.') ||
        userStore.user.email.includes('@mm.dk'))
    "
  >
    <p class="fixed top-0 right-0 mt-24 mr-5 inline-block">
      <a :href="`${editUrl}`">
        <CommonButton bg="bg-blue" text="text-white">
          {{ $t('Edit2') }}
        </CommonButton></a
      >
    </p>
  </div>
</template>

<script setup lang="ts">
const userStore = useUserStore()
const config = useRuntimeConfig()

const props = withDefaults(
  defineProps<{
    id?: number
    link?: string
    shot?: string
  }>(),
  { id: 0, link: '', shot: 'hot' }
)

const editUrl = computed(() => {
  switch (props.shot) {
    case 'hot':
      return `https://${config.public.site.hotshoturl}/${props.link}.aspx?id=${props.id}`

    case 'job':
      return `https://jobshot.alrow.media/${props.link}/${props.id}`

    default:
      throw new Error(`No Shot named ${props.shot} is defined in QuickEdit.vue`)
  }
})
</script>
